import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/app/hooks';

import { flight } from '@/app/stores/dataSlice/preFlightSlice';
import { LandParams, TakeOffParams, usePostLandMutation, usePostTakeOffMutation, usePostPrepareMutation } from '@/features/api/copApiEndpoints/flightAPI';
import TakeOffLandModal from './TakeOffLandModal';
import Tooltip from '@mui/joy/Tooltip';
import { downloadPdf } from '@/features/functions/downloadPdfFile';
import { v4 as uuidv4 } from "uuid";


// Styles
import styles from './FlightTab.module.scss';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'; 
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faBurst, faCarCrash, faCircleCheck, faCross, faDownload, faExclamationTriangle, faPlaneCircleCheck, faPlaneArrival, faPlaneDeparture, faPlus, faBox, faQuestion, faHelicopterSymbol, faPlane, faDeleteLeft, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Delivery } from '@/app/stores/deliverySlice';
import { useGetPayloadQuery } from '@/features/api/copApiEndpoints/payloadAPI';
import { useGetAlertsQuery, usePostDeleteAlertMutation, usePostDeactivateAlertMutation, usePostCommentAlertMutation, postCommentAlertBody, useGetAlertCommentsQuery, getAlertCommentsParams } from '@/features/api/copApiEndpoints/alertsAPI';
import { Button, Chip, Modal, Snackbar } from '@mui/joy';
import AlertComments from './AlertComments';



export default function FlightTab() {
    const preFlightState = useAppSelector((state) => state.preFlight);
    const [postTakeOffResult, setPostTakeOffResult] = useState(); 
    const [postLandResult, setPostLandResult] = useState(); 
    const [postPrepareResult, setPostPrepareResult] = useState();
    const [isShowTakeOffModal, setIsShowTakeOffModal] = useState(false);
    const [takeOffLand, setTakeOffLand] = useState('');
    const [flightId, setFlightId] = useState('');
    
    return (
        <>
            <TableContainer component={Paper} style={{backgroundColor: styles.tabBgColor, border: styles.tabBorder, borderRadius: styles.tabBorderRadius}}>
                <Table aria-label="collapsible table">
                    <TableHead >
                        <TableRow style={{borderBottom: 'unset'}}> 
                            <TableCell style={{borderBottom: 'unset'}}/>
                            <TableCell style={{ color: styles.headerColor, borderBottom: 'unset' }}>Time</TableCell>
                            <TableCell align="left" style={{ color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize, borderBottom: 'unset' }}>Type</TableCell>
                            <TableCell align="left" style={{ color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize, borderBottom: 'unset'  }}>Departure</TableCell>
                            <TableCell align="left" style={{ color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize, borderBottom: 'unset'  }}>Arrival</TableCell>
                            <TableCell align="left" style={{ color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize, borderBottom: 'unset'  }}>Aircraft</TableCell>
                            <TableCell align="left" style={{ color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize, borderBottom: 'unset'  }}>Status</TableCell>
                            <TableCell align="right" style={{ color: styles.headerColor, fontFamily: styles.headerFontFamily, fontSize: styles.headerFontSize, borderBottom: 'unset'  }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {preFlightState.flightList.map((row) => (
                        <Row 
                            key={row.flight_no} 
                            row={row} 
                            result={[postPrepareResult, postTakeOffResult, postLandResult]}
                            actionCall={(flightId: string, modal: boolean, type: string) => {
                                setIsShowTakeOffModal(modal);
                                setFlightId(flightId);
                                setTakeOffLand(type);
                            }}
                        />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TakeOffLandModal
                params={{flightId: flightId, type: takeOffLand}}
                open={isShowTakeOffModal}
                handleClose={() => setIsShowTakeOffModal(false)}
                updateStatus={(takeOffResult, landResult) => {setPostTakeOffResult(takeOffResult); setPostLandResult(landResult);}}
            /> 
        </>
    );
  }

function Row(props: { row: flight, result: any[], actionCall: (flightId: string, modal: boolean, type: string) => void }) {
  const { row, result, actionCall } = props;
  const [open, setOpen] = React.useState(false);
  const hubState = useAppSelector((state) => state.hub);
  const deliveryState = useAppSelector((state) => state.delivery);
  const { isError: getAlertsIsError, data: getAlertsData } = useGetAlertsQuery({}, { pollingInterval: 1000 });
  const payload = deliveryState.deliveryList.find(delivery => delivery.id == row.order.id)?.payload;
  // Step 1: Filter getAlertsData based on hubId ; Step 2: Remove duplicates from departureHubAlerts based on message
  const departureHub = hubState.hubList.find((item) => item.id == row.departureHub);
  let departureHubAlerts = getAlertsData?.filter((item) => item.hubId == departureHub?.id);
  let uniqueDepartureAlerts = departureHubAlerts?.filter((item, index, self) => index === self.findIndex((t) => (t.type === item.type)));
  // Step 1: Filter getAlertsData based on hubId ; Step 2: Remove duplicates from arrivalHubAlerts based on message
  const arrivalHub = hubState.hubList.find((item) => item.id == row.arrivalHub);
  let arrivalHubAlerts = getAlertsData?.filter((item) => item.hubId == arrivalHub?.id);
  let uniqueArrivalAlerts = arrivalHubAlerts?.filter((item, index, self) => index === self.findIndex((t) => (t.type === item.type)));
  // Callback for action buttons linked to the flight
  const onPrepare = () => { if(row.actions[0]) {actionCall(row.flight_no, true, 'Prepare') }}
  const onTakeOff = () => { if(row.actions[1]) {actionCall(row.flight_no, true, 'Take-Off') }}
  const onLanding = () => { if(row.actions[2]) {actionCall(row.flight_no, true, 'Land') }}
  // Callback for action buttons linked to alerts
  const [selectedAlert, setSelectedAlert] = useState<string>('');
  const [showComments, setShowComments] = useState<boolean>(false);
  const [postDeactivateAlert, postDeactivateAlertResult] = usePostDeactivateAlertMutation();
  const [snackbarDeactivateAlert, setSnackbarDeactivateAlert] = useState<boolean>(false);
  const [snackbarErrorDeactivateAlert, setSnackbarErrorDeactivateAlert] = useState<boolean>(false);
  useEffect(() => {
    if(postDeactivateAlertResult.isSuccess) {setSnackbarDeactivateAlert(true);}
    else if(postDeactivateAlertResult.isError) {setSnackbarErrorDeactivateAlert(true);}
  },[postDeactivateAlertResult])
  const onDeactivateAlert = (alertId: string | undefined) => { if (alertId != undefined) {postDeactivateAlert(alertId)} }
  const onDisplayAlertComments = (alertId: string | undefined) => { if (alertId != undefined) {setSelectedAlert(alertId); setShowComments(true)}}

  return (
    <React.Fragment>
      {/* HEADER */} 
      <TableRow sx={{ '& > *': { borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily } }}> 
        <TableCell style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>
          <IconButton
            aria-label="expand row"
            color='info'
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>
            { new Date(row.flight_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}  <br />
            {new Date(row.flight_time).toLocaleDateString()}
        </TableCell>
        <TableCell align="left" style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>{row.cargo}</TableCell>
        <TableCell align="left" style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>
          {departureHub?.name} 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '0px'}}>{uniqueDepartureAlerts?.map((alert, idx) => {
            return (<div key={idx} className={styles.alertIcon}> 
              {alert.type == 'Air accident' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faPlane} color='red'/>} 
              {alert.type == 'Landing pad' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faHelicopterSymbol} color='red'/>} 
              {alert.type == 'Payload' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faBox} color='red'/>} 
              {alert.type == 'Other' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faExclamationTriangle} color='red'/>} 
            </div>)
          })}</div>
        </TableCell>
        <TableCell align="left" style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>{arrivalHub?.name}</div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>{uniqueArrivalAlerts?.map((alert, idx) => {
            return (<div key={idx} className={styles.alertIcon}> 
              {alert.type == 'Air accident' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faPlane} color='red'/>} 
              {alert.type == 'Landing pad' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faHelicopterSymbol} color='red'/>} 
              {alert.type == 'Payload' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faBox} color='red'/>} 
              {alert.type == 'Other' && alert?.orderId == row.order.id && <FontAwesomeIcon icon={faExclamationTriangle} color='red'/>} 
            </div>)
          })}</div>
        </TableCell>
        <TableCell align="left" style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>
          {row.aircraft} <br />
          {"Bat. :" + row.batteries}
        </TableCell>
        <TableCell align="left" style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>{row.flight_status}</TableCell>
        <TableCell align="right" style={{ borderBottom: 'unset', color: styles.textColor, fontFamily: styles.rowFontFamily }}>
            <div className={styles.actions}>
                {(row.cargo == 'Empty flight') && <div className={row.actions[0] ? ((result[0] != undefined && result[0].isLoading)  ? styles.flightButtonLoading : styles.flightButton) : styles.disable} onClick={onPrepare}> <FontAwesomeIcon icon={faPlaneCircleCheck} color='white'/> </div> }
                <div className={row.actions[1] ? ((result[1] != undefined && result[1].isLoading)  ? styles.flightButtonLoading : styles.flightButton) : styles.disable} onClick={onTakeOff}> <FontAwesomeIcon icon={faPlaneDeparture} color='white'/> </div>
                <div className={row.actions[2] ? ((result[2] != undefined && result[2].isLoading) ? styles.flightButtonLoading : styles.flightButton) : styles.disable} onClick={onLanding}> <FontAwesomeIcon icon={faPlaneArrival} color='white'/> </div>
            </div>
        </TableCell>
      </TableRow>
      {/* CONTENT */} 
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'unset', color: 'white', backgroundColor: styles.rowBgColor }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, py: 3}}>
              {/* FLIGHT ID */} 
              <Typography align='right' gutterBottom component="div" style={{color: styles.rowColor, marginBottom: '20px'}}>
                Flight ID : {row.flight_no}
              </Typography>
              {/* GROUND HANDLING OPERATIONS */} 
              <Typography variant="h6" gutterBottom component="div" style={{color: styles.rowColor}}>
                Ground Handling Operations
              </Typography>
              <Table size="small" aria-label="purchases" style={{marginBottom: '20px'}} >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderColor: styles.rowColor, color: styles.rowColor }}>Time</TableCell>
                    <TableCell style={{ borderColor: styles.rowColor, color: styles.rowColor }}>Type</TableCell>
                    <TableCell style={{ borderColor: styles.rowColor, color: styles.rowColor }}>Done by</TableCell>
                    <TableCell align="right" style={{ borderColor: styles.rowColor, color: styles.rowColor }}>Status</TableCell>
                    <TableCell align="right" style={{ borderColor: styles.rowColor, color: styles.rowColor }}>Progress</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.groundHandlingActions.map((groundHandlingAction) => (
                    <TableRow key={groundHandlingAction.startTime}>
                      <TableCell component="th" scope="row" style={{borderBottom: 'unset', color: styles.rowColor}}> { new Date(groundHandlingAction.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</TableCell>
                      <TableCell style={{borderBottom: 'unset', color: styles.rowColor}}>{groundHandlingAction.type}</TableCell>
                      <TableCell style={{borderBottom: 'unset', color: styles.rowColor}}>
                        {groundHandlingAction.type == 'Prepare delivery flight' && deliveryState.deliveryList.find(delivery => delivery.id == row.order.id)?.prepared_by}
                        {groundHandlingAction.type == 'Complete delivery flight' && deliveryState.deliveryList.find(delivery => delivery.id == row.order.id)?.unloaded_by}
                        {groundHandlingAction.type == 'Complete empty flight' && 'n.a.'}
                        {groundHandlingAction.type == 'Prepare empty flight' && 'n.a.'}
                      </TableCell>
                      <TableCell align="right" style={{borderBottom: 'unset', color: styles.rowColor}}>{groundHandlingAction.status}</TableCell>
                      <TableCell align="right" style={{borderBottom: 'unset', color: styles.rowColor}}>{groundHandlingAction.progress}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* SHIPMENT if cargo flight */} 
              {(row.cargo == 'Cargo flight') && <>
                <Typography variant="h6" gutterBottom component="div" style={{color: styles.textColor2}}>
                  Shipment on board [{Number((payload?.actualMass ?? payload?.mass ?? 0).toFixed(2))} kg {payload?.actualMass ? '(measured)' : '(estimated)'}]
                </Typography>
                <Table size="small" aria-label="purchases" style={{marginBottom: '20px'}}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ borderColor: styles.textColor2, color: styles.textColor2 }}>DG</TableCell>
                      <TableCell style={{ borderColor: styles.textColor2, color: styles.textColor2 }}>Product</TableCell>
                      <TableCell style={{ borderColor: styles.textColor2, color: styles.textColor2 }}>Container</TableCell>
                      <TableCell align="right" style={{ borderColor: styles.textColor2, color: styles.textColor2 }}>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payload?.items.map((item, idx) => (
                      <TableRow key={idx}>
                        <TableCell style={{borderBottom: 'unset', color: styles.textColor2}}>{item.product?.hazards ? (<div style={{color:  styles.textColor2}}><FontAwesomeIcon icon={faExclamationTriangle}/></div>):(<>n.a.</>)}</TableCell>
                        <Tooltip placement="bottom-start" title={
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              p: 1,
                            }}
                          >
                            <div style={{color: 'rgb(255, 255, 255, 100%)'}}> 🧪 Product details</div>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', mt: 1 }}>
                              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, width: '100%' }}>
                                <Chip size="sm" color="neutral" sx={{ fontWeight: 'lg' }}> {item.product.name} </Chip>
                                <Chip size="sm" color="danger" sx={{ fontWeight: 'lg' }}> {item.product.unNumber} </Chip>
                                <Chip size="sm" color="danger" sx={{ fontWeight: 'lg' }}> {item.product.ergCode} </Chip>
                              </Box>
                              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, width: '100%' }}>
                                <Chip size="sm" color="neutral" sx={{ fontWeight: 'lg', marginBottom: '0px' }}> PSN - {item.product.properShippingName} </Chip>
                                <Chip size="sm" color="warning" sx={{ fontWeight: 'lg' }}> PG {item.product.packingGroup} </Chip>
                                <Chip size="sm" color="success" sx={{ fontWeight: 'lg' }}> {item.product.density.toFixed(2)} kg/L</Chip>
                              </Box>
                              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, width: '100%' }}>
                                <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', fontSize:'11px', color: 'rgb(255, 255, 255, 60%)'}}>Class(es)</div>
                                {item.product.hazards?.map((item, idx) => (<Chip key={uuidv4()} size="sm" color="neutral" sx={{ fontWeight: 'lg' }}> {item} </Chip>))}
                              </Box>
                            </Box>
                          </Box>
                        }>
                          <TableCell component="th" scope="row" style={{borderBottom: 'unset', color: styles.textColor2}}> { item.product.name }</TableCell>
                        </Tooltip>
                        <TableCell style={{borderBottom: 'unset', color: styles.textColor2}}>{item.containerType.name}</TableCell>
                        <TableCell align="right" style={{borderBottom: 'unset', color: styles.textColor2}}>{item.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table> 
              </>}
              {/* ORDER */} 
              <Typography variant="h6" gutterBottom component="div" style={{color: styles.textColor}}>
                Order details
              </Typography>
              <Table size="small" aria-label="purchases" style={{marginBottom: '20px'}}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderColor: styles.textColor, color: styles.textColor }}>Id</TableCell>
                    <TableCell style={{ borderColor: styles.textColor, color: styles.textColor }}>Client ref</TableCell>
                    <TableCell align="right" style={{ borderColor: styles.textColor, color: styles.textColor }}>Status</TableCell>
                    <TableCell align="right" style={{ borderColor: styles.textColor, color: styles.textColor }}>Doc.</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{borderBottom: 'unset', color: styles.textColor, }}> { row.order?.id }</TableCell>
                      <TableCell style={{borderBottom: 'unset', color: styles.textColor}}>{row.order?.clientRef}</TableCell>
                      <TableCell align="right" style={{borderBottom: 'unset', color: styles.textColor}}>{row.order?.status}</TableCell>
                      <TableCell align="right" style={{borderBottom: 'unset', color: styles.textColor, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                          <div onClick={() => downloadPdf(row.order?.transportDocumentUrl)}> 
                            <IconButton aria-label="chat" color="info" size='small' disabled={row.order?.transportDocumentUrl ? false : true}> <DownloadRoundedIcon fontSize='small'/> </IconButton> 
                          </div>
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
              {/* ALERTS if any */} 
              {(getAlertsData != undefined && getAlertsData?.length > 0 ) && <>
                <Typography variant="h6" gutterBottom component="div" style={{color: styles.textColor4}}>
                  Alerts
                </Typography>
                <Table size="small" aria-label="purchases" style={{marginBottom: '20px'}}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ borderColor: styles.textColor4, color: styles.textColor4 }}>Id</TableCell>
                      <TableCell style={{ borderColor: styles.textColor4, color: styles.textColor4 }}>Hub</TableCell>
                      <TableCell style={{ borderColor: styles.textColor4, color: styles.textColor4 }}>Type</TableCell>
                      <TableCell style={{ borderColor: styles.textColor4, color: styles.textColor4, maxWidth: '350px' }}>Message</TableCell>
                      <TableCell align="right" style={{ borderColor: styles.textColor4, color: styles.textColor4 }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getAlertsData?.map((item, idx) => (
                      <TableRow key={idx}>
                        <TableCell style={{borderBottom: 'unset', color: styles.textColor4}}>{new Date(item.timestamp ?? '').toLocaleString()}</TableCell>
                        <TableCell component="th" scope="row" style={{borderBottom: 'unset', color: styles.textColor4}}> { hubState.hubList.find((i) => i.id == item.hubId)?.name }</TableCell>
                        <TableCell component="th" scope="row" style={{borderBottom: 'unset', color: styles.textColor4}}> { item.type }</TableCell>
                        <TableCell style={{borderBottom: 'unset', color: styles.textColor4, maxWidth: '350px', wordWrap: 'break-word'}}>{item.message}</TableCell>
                        <TableCell align="right" style={{borderBottom: 'unset', color: styles.textColor4}}>
                          <IconButton aria-label="chat" color="info" size='small' onClick={() => onDisplayAlertComments(item.id)}> <MapsUgcIcon fontSize='inherit'/> </IconButton>
                          <IconButton aria-label="delete" color="info" size='small' onClick={() => onDeactivateAlert(item.id)}> <CancelRoundedIcon fontSize='inherit'/> </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table> 
              </>}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Snackbar // Display api call SUCCESS result
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbarDeactivateAlert}
          variant='soft'
          color='success'
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setSnackbarDeactivateAlert(false);
          }}
          endDecorator={
            <Button
              onClick={() => setSnackbarDeactivateAlert(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleCheck}/> The alert has been succesfully deactivated !
        </Snackbar>
      <Snackbar // Display api call ERROR result
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbarErrorDeactivateAlert}
          variant='soft'
          color='danger'
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setSnackbarErrorDeactivateAlert(false);
          }}
          endDecorator={
            <Button
              onClick={() => setSnackbarErrorDeactivateAlert(false)}
              size="sm"
              variant="soft"
              color="danger"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleExclamation}/> The alert has not been succesfully deactivated !
        </Snackbar>
      {showComments && <AlertComments open={showComments} handleClose={() => setShowComments(false)} alertId={selectedAlert}/>}
    </React.Fragment>
  );
}