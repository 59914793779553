import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import { useAppDispatch } from './app/hooks';
// import { logout } from './app/mainSlice';
import { useWhoAmIQuery } from './features/api/copAPI';
import { useGetCompanyQuery } from './features/api/copApiEndpoints/companyAPI';
import { Auth } from './features/pages/Auth/Auth';
import { Dashboard } from './features/pages/Dashboard/Dashboard';
import { Deliveries } from './features/pages/Deliveries/Deliveries';
import { GroundHandling } from './features/pages/GroundHandling/GroundHandling';
import { GroundHandlingOperation } from './features/pages/GroundHandling/components/GroundHandlingOperation/GroundHandlingOperation';
import { Help } from './features/pages/Help/Help';
import { Hubs } from './features/pages/Hubs/Hubs';
import { Products } from './features/pages/Products/Products';
import { Reporting } from './features/pages/Reporting/Reporting';
import { Settings } from './features/pages/Settings/Settings';
import { Users } from './features/pages/Users/Users';
import { Preflight } from './features/opsPages/Preflight/Preflight';


function App() {
  // const dispatch = useAppDispatch();

  // Check authentication every hour
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const {
    data: whoAmI,
    // isError: whoAmIError,
    refetch: whoAmIRefetch,
  } = useWhoAmIQuery({}, { refetchOnMountOrArgChange: true });

  // TODO: currently passing '' to useGetCompanyQuery will query all companies instead of 0 => ask BE to fix it. Temporary fix: pass '1' instead of ''
  const { refetch: refetchCompany } = useGetCompanyQuery(
    whoAmI?.companyId || '1',
    { skip: !whoAmI?.companyId },
  );

  useEffect(() => {
    if (whoAmI?.companyId) {
      refetchCompany();
    }
  }, [refetchCompany, whoAmI?.companyId]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      whoAmIRefetch();
    }, 3600000);

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [whoAmIRefetch]);

  // useEffect(() => {
  //   if (whoAmIError) {
  //     dispatch(logout());
  //   }
  // }, [dispatch, whoAmI, whoAmIError]);

  return (
    <BrowserRouter>
      <div id="app">
        <Routes>
          <Route path="/auth" element={<Auth />} />
          {/* OPERATIONS */}
          <Route path="/" element={<Preflight />} />

          {/* CUSTOMER */}
          {/* <Route path="/" element={<Dashboard />} /> */}
          {/* <Route path="/deliveries" element={<Deliveries />} />
          <Route
            path="/deliveries/groundHandling/:operationType"
            element={<GroundHandling />}
          />
          <Route
            path="/deliveries/groundHandling/:operationType/checklist"
            element={<GroundHandlingOperation />}
          />
          <Route path="/help" element={<Help />} />
          <Route path="/hubs" element={<Hubs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/users" element={<Users />} /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
