import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { login, logout } from '@/app/mainSlice';
import { msalInstance, loginRequest } from '@/authConfig';
import { useWhoAmIQuery } from '@/features/api/copAPI';
import {
  isTokenExpired,
  renewTokenSilently,
} from '@/helpers/renewTokenSilently';
import { AuthLoading } from './AuthLoading';

export default function AuthWrapper({ children }: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const { refetch: whoAmIRefetch } = useWhoAmIQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const mainState = useAppSelector((state) => state.main);

  useEffect(() => {
    (async () => {
      await msalInstance.initialize();
      const response = await msalInstance.handleRedirectPromise();
      if (response !== null) {
        const accessToken = response.accessToken;
        Cookies.set('accessToken', accessToken, {
          secure: true,
          sameSite: 'strict',
          expires: response.expiresOn?.getTime() || undefined,
        });
        console.log('Successfully logged in!');
        dispatch(login());
      } else {
        const currentAccounts = msalInstance.getAllAccounts();
        if (currentAccounts.length === 0) {
          dispatch(logout());
          msalInstance.loginRedirect(loginRequest);
        } else if (isTokenExpired()) {
          try {
            await renewTokenSilently();
            dispatch(login());
          } catch (err) {
            console.log('Failed to renew token silently', err);
            dispatch(logout());
            msalInstance.loginRedirect(loginRequest);
          }
        }
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    // Calling whoAmIRefetch right after dispatch(login()) always return error. Temporary fix: wait ~1s before refetch whoAmI.
    if (mainState.isLoggedIn) {
      timeoutId = setTimeout(() => {
        whoAmIRefetch();
      }, 800);
    }
    // Cleanup
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [mainState.isLoggedIn, whoAmIRefetch]);

  return (
    <>
      {mainState.isLoggedIn ? (
        <>{children}</>
      ) : (
        // TODO: add authentication loading UI
        <AuthLoading />
      )}
    </>
  );
}
