import { Checklist } from "@/app/stores/dataSlice/groundHandlingSlice";

export type Step = {
  step: number,
  action: string,
  substeps?: Step[],
}

export type JsonChecklist = {
  title: string;
  steps: Step[];
}

export type fetchedChecklistsData = { 
  checklists: JsonChecklist[];
}

async function fetchChecklistsJsonData(url: string): Promise<fetchedChecklistsData | null> {
  try {
    const response = await fetch(url);

    if (response.ok) {
      const data: fetchedChecklistsData = await response.json();
      return data;
    } else {
      console.error(`Failed to fetch data from ${url}. Status code: ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return null;
  }
}
function mapToDesiredType(fetchedData: fetchedChecklistsData): Checklist[] {
  // Implement the mapping or transformation logic based on your needs
  // This is where you convert the original data into the desired output type
  const outputData: Checklist[] = fetchedData.checklists.map((item: JsonChecklist) => {
    return {
      title: item.title,
      tasksList: item.steps.map((item: Step) => {
        return {
          step: item.step,
          action: item.action,
        };
      }),
    };
  });
  return outputData;
}
export async function fetchCheclistsDataAndMap (url: string): Promise<Checklist[]> {
  try {
    const fetchedData = await fetchChecklistsJsonData(url);
    if (fetchedData !== null) {
      return mapToDesiredType(fetchedData);
    } else {
      // Handle the case where fetchChecklistsData returns null
      return [];
    }
  } catch (error) {
    // Handle errors here
    console.error("Error fetching and mapping data:", error);
    return [];
  }
}