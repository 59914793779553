import { useEffect, useCallback } from 'react';

export function useOnClickOutsideSelectors(
  selectors: string[],
  handler: EventListenerOrEventListenerObject
) {
  const outsideClickListener = useCallback(
    (event: PointerEvent) => {
      // only look for closest if the event target is still connected to the dom
      if (!(event.target as HTMLElement).isConnected || event.button !== 0)
        return;
    
      let selectorMatch = false;
      for (const selector of selectors) {
        const closestMatchingElement = (event.target as HTMLElement)?.closest(
          selector
        );
        if (closestMatchingElement) {
          selectorMatch = true;
          break;
        }
      }

      if (!selectorMatch) {
        if (typeof handler === 'function') {
          handler(event);
        } else {
          handler.handleEvent(event);
        }
      }
    },
    
    [handler, selectors]
  );

  return useEffect(() => {
    document.addEventListener('pointerup', outsideClickListener);

    return () => {
      document.removeEventListener('pointerup', outsideClickListener);
    };
  }, [outsideClickListener]);
}
