import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import Modal from '@mui/material/Modal';

import styles from './TakeOffLandModal.module.scss';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { faArrowRight, faArrowRightFromBracket, faArrowTurnDown, faArrowTurnUp, faBan, faThumbsUp, faTurnUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '@/app/hooks';
import { resetGroundHandlingOperation } from '@/app/stores/dataSlice/groundHandlingSlice';
import { LandParams, TakeOffParams, usePostLandMutation, usePostPrepareMutation, usePostTakeOffMutation } from '@/features/api/copApiEndpoints/flightAPI';

export default function TakeOffLandModal({
  params,
  open,
  handleClose,
  updateStatus,
}: {
  params: {flightId: string, type: string};
  open: boolean;
  handleClose: () => void;
  updateStatus: (prepareResult: any, stakeOffResult: any, landResult: any) => void;
}) {
  const [postPrepare, postPrepareResult] = usePostPrepareMutation();
  const [postTakeOff, postTakeOffResult] = usePostTakeOffMutation(); 
  const [postLand, postLandResult] = usePostLandMutation(); 
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  let title, content: string;
  if (params.type == 'Take-Off'){
    title = 'Take-Off';
    content = 'Do you confirm that the aircraft will proceed to Take-Off ?';
  } else if (params.type == 'Land') {
    title = 'Landed';
    content = 'Do you confirm that the aircraft has landed ?';
  } else if (params.type == 'Prepare') {
    title = 'Prepare';
    content = 'Do you confirm that you are starting the pre-flight checks for this flight ?';
  } else {
    title = 'Error';
    content = 'Error - Request support from the technical team';
  }

  const handleConfirmation = () => {
    if (params.type == 'Take-Off') {
      const paramsMutation: TakeOffParams = {flightId: params.flightId};
      postTakeOff(paramsMutation);
      updateStatus(postPrepareResult, postTakeOffResult, postLandResult);
      handleClose();
    } else if (params.type == 'Land') {
      const paramsMutation: TakeOffParams = {flightId: params.flightId};
      postLand(paramsMutation);
      updateStatus(postPrepareResult, postTakeOffResult, postLandResult);
      handleClose();
    } else if (params.type == 'Prepare') {
      const paramsMutation: TakeOffParams = {flightId: params.flightId};
      postPrepare(paramsMutation);
      updateStatus(postPrepareResult, postTakeOffResult, postLandResult);
      handleClose();
    }
  }

  return (
    <Modal
      className={styles.modalWrapper}
      open={open}
      aria-labelledby="compliance-result-modal"
      aria-describedby="compliance-result-modal"
    >
      <div className={styles.cardModal}>
        <div className={styles.header}>
          <p>{title}</p>
        </div>
        <div className={styles.content}>
          <p>{content}</p>
        </div>
        <div className={styles.footer}>
          < IconButton
            icon={faThumbsUp}
            content='Confirm'
            onClick={handleConfirmation}
            // rotate="rotate180"
            // color='unset'
            // noBorder={true}
          />
          < IconButton
            icon={faArrowTurnDown}
            content='Return'
            onClick={handleClose}
            reversed={true}
            rotate='rotate90'
            color='unset'
            noBorder={true}
          />
        </div>
      </div>
    </Modal>
  );
}
