import { copAPI } from '../copAPI';

type GroundOperationParams = {
  startTime?: string;
  endTime?: string;
  hubId: string;
};

export type ChecklistProgressBody = {
  progress: string;
}

export type ChecklistProgressParams = {
  orderId: string;
  type: string;
};
export type ChecklistURLParams = {
  orderId: string;
  type: string;
};

export type ChecklistProgressReturn = {
  progress: string;
};
export type ChecklistURLReturn = {
  url: string;
};

export type GroundHandlingActionParams = {
  orderId: string;
  type: string;
};

type HubStatusParams = {
  hubId: string;
};

type HubStatusReturn = {
  status: string;
};

export type GroundOperationReturnType = {
  groundHandlingType: string;
  groundHandlingStartTime: string;
  groundHandlingStatus: string;
  packages: {
    packaging: {
      id: string;
    };
    content: {
      type: string;
      id: string;
    };
  }[];
  drone: string;
  flightStatus: string;
  orderId: string;
  orderReference: string;
  scheduledGroundHandlingTime: string;
  otherHub: string;
};

const startTime = new Date('2024-01-15');
startTime.setHours(17)
startTime.setMinutes(0)
const endTime = new Date('2024-01-15');
endTime.setHours(22)
endTime.setMinutes(0)

export const groundHandlingAPI = copAPI.injectEndpoints({
  endpoints: (builder) => ({
    getGroundHandlingOperations: builder.query<GroundOperationReturnType[],GroundOperationParams>({
      query: (params) => ({
        url: `./groundhandling/${params.hubId}`,
        method: 'GET',
        params: params, //{
        //   startTime: startTime.toISOString(), // TODO: update with current time '2023-10-23'
        //   endTime: endTime.toISOString(), // TODO: update with current time '2023-10-23'
        //   ...params,
        // },
      }),
    }),
    getChecklistProgress: builder.query<ChecklistProgressReturn, ChecklistProgressParams>({
      query: (params) => ({
        url: `./groundhandling/${params.orderId}/checklist/${params.type}`,
        method: 'GET',
        params: params,
      }),
    }),
    getChecklistsURL: builder.query<ChecklistURLReturn, ChecklistURLParams>({
      query: (params) => ({
        url: `./groundhandling/${params.orderId}/checklisturl/${params.type}`,
        method: 'GET',
        params: params,
      }),
    }),
    getHubStatus: builder.query<HubStatusReturn, HubStatusParams>({
      query: (params) => ({
        url: `./groundhandling/${params.hubId}/status`,
        method: 'GET',
        params: params,
      }),
    }),
    postChecklistProgress: builder.mutation({
      query: ({params, body}:{params: ChecklistProgressParams, body: ChecklistProgressBody}) => ({
        url: `/groundhandling/${params.orderId}/checklist/${params.type}`,
        method: 'POST',
        params: params,
        body: body,
      }),
    }),
    postStartGroundHandlingOperation: builder.mutation({
      query: (params: GroundHandlingActionParams) => ({
        url: `/groundhandling/${params.orderId}/start/${params.type}`,
        method: 'POST',
        params: params,
      }),
    }),
    postEndGroundHandlingOperation: builder.mutation({
      query: (params: GroundHandlingActionParams) => ({
        url: `/groundhandling/${params.orderId}/end/${params.type}`,
        method: 'POST',
        params: params,
      }),
    }),
  }),
});

export const {
  useGetGroundHandlingOperationsQuery,
  useGetChecklistProgressQuery,
  useGetChecklistsURLQuery,
  useGetHubStatusQuery,
  usePostChecklistProgressMutation,
  usePostEndGroundHandlingOperationMutation,
  usePostStartGroundHandlingOperationMutation,
} = groundHandlingAPI;
