import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IconButton.module.scss';

export type IconButtonProps = {
  icon: IconDefinition,
  content: string,
  onClick: () => void;
  disabled?: boolean,
  reversed?: boolean,
  rotate?: string, // degrees
  color?: 'unset' | 'unset_blue' | 'red' | 'green' | 'mobileMenu',
  noBorder?: boolean,
}

export function IconButton(props: IconButtonProps) {
  const { icon, content, onClick, disabled, reversed, rotate, color, noBorder } = props;

  return (
    <div>
      { reversed === true ? (
        <div 
          className={`
            ${styles.button_icon} 
            ${disabled ? styles.disabled_button : ''} 
            ${color ? styles[color] : ''}
            ${noBorder ? styles.no_border: ''}`} 
          onClick={onClick}>
          {content}
          <FontAwesomeIcon 
            icon={icon} 
            className={rotate ? styles[rotate] : ""}
          />
        </div>
      ) : (
        <div 
          className={`
            ${styles.button_icon} 
            ${disabled ? styles.disabled_button : ''} 
            ${color ? styles[color] : ''}
            ${noBorder ? styles.no_border: ''}`} 
          onClick={onClick}>
          <FontAwesomeIcon 
            icon={icon} 
            className={rotate ? styles[rotate] : ""}
          />          
          {content}
        </div>
      )}
    </div>
  );
}
