import { AnimatePresence, motion } from 'framer-motion';
import { useOnClickOutsideSelectors } from '@/hooks/useOnClickOutsideSelectors';
import styles from './DropDownMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type DropDownMenuProps = {
  menuItems: DropDownMenuItem[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DropDownMenuItem =
  | {
      icon?: IconDefinition;
      name: string;
      action: (props: any) => void;
    }
  | 'seperator';

export const DropDownMenu = ({
  menuItems,
  isOpen,
  setIsOpen,
}: DropDownMenuProps) => {
  useOnClickOutsideSelectors(['.drop-down-menu', '.user-account-btn'], () => setIsOpen(false));

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={styles['drop-down-menu']}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: -20,
            transition: {
              duration: 0.1,
            },
          }}
        >
          {menuItems.map((menuItem, idx) => {
            if (typeof menuItem === 'object') {
              return (
                <div
                  key={idx}
                  className={styles['menu-item']}
                  onClick={menuItem.action}
                >
                  {menuItem.icon && <FontAwesomeIcon icon={menuItem.icon} />}
                  {menuItem.name}
                </div>
              );
            } else {
              return <hr key={idx} className={styles['solid-separator']} />;
            }
          })}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
