import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

// import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updateError } from '@/app/mainSlice';
import { useGetContainersQuery } from '@/features/api/copApiEndpoints/containerAPI';
import { useGetHubsQuery } from '@/features/api/copApiEndpoints/hubAPI';
import { useGetOrdersQuery } from '@/features/api/copApiEndpoints/orderAPI';
import { useGetProductsQuery } from '@/features/api/copApiEndpoints/productAPI';

import { Sidebar } from '../Sidebar/Sidebar';
import { Topbar } from '../Topbar/Topbar';

import styles from './MainLayout.module.scss';
import { MobileSidebar } from '../Sidebar/MobileSidebar';

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  // Moving from Auth page to Azure log in
  // const navigate = useNavigate();
  // const mainState = useAppSelector((state) => state.main);

  // useEffect(() => {
  //   if (!mainState.isLoggedIn) {
  //     navigate('/auth');
  //   }
  // }, [mainState.isLoggedIn, navigate]);

  // Fetch shared data to saved to redux state, tbd whether the fetching should be move to each page
  const { isLoading: productLoading, isError: productErr } = useGetProductsQuery({});
  const { isLoading: hubLoading, isError: hubErr } = useGetHubsQuery({});
  const { isLoading: containerLoading, isError: containerErr } = useGetContainersQuery({});
  const { isLoading: orderLoading, isError: orderErr } = useGetOrdersQuery({ limit: '100' });

  useEffect(() => {
    if (productErr || hubErr || containerErr || orderErr) {
      dispatch(updateError({ id: '1', value: false }));
    }
  }, [containerErr, dispatch, hubErr, orderErr, productErr]);
  
  const props3 = useSpring({
    // visibility: show ? 'visible' : 'hidden',
  });

  const mainState = useAppSelector((state) => state.mobileSidebar);


  return (
    < div >
      <div className={styles.main_layout} style={{display: mainState.showMobileMenu ? 'none' : ''}}>
        {/* <Sidebar /> */}
        <div className={styles.content_wrapper}>
          <div className={styles.header}>
            <Topbar />
          </div>
          {productLoading || hubLoading || orderLoading || containerLoading ? (
            <div
              className={styles.body}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress sx={{ color: 'black' }} />
            </div>
          ) : (
            <div className={styles.body}>{children}</div>
          )}
        </div>
      </div>
      {mainState.showMobileMenu && (
        <animated.div
        style={props3}
        >
          <MobileSidebar />
        </animated.div> 
      )}
    </div>
  );
};
