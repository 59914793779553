import { 
  GroundHandlingOperation } from "@/app/stores/dataSlice/groundHandlingSlice";
import { 
  GroundOperationReturnType } from "../copApiEndpoints/groundHandlingAPI";


export function getGroundOperationAdapterAPI(data: GroundOperationReturnType[]): GroundHandlingOperation[] {
    // This function maps the fetched data from getGroundHandlingOperations to the redux state groundHandlingOperations.
    return (data.map((item: GroundOperationReturnType) => {
        return {
            type: item.groundHandlingType,
            startTime: item.groundHandlingStartTime,
            scheduledTime: item.scheduledGroundHandlingTime,
            orderId: item.orderId,
            orderRef: item.orderReference,
            droneId: item.drone,
            packages: item.packages,
            departureHubId: item.otherHub,
        };
      })
    );
}
