import LoadingUI from "../Modal/LoadingUI";

export function AuthLoading() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingUI color="black" />
      <h2>Checking your authentication...</h2>
    </div>
  );
}
