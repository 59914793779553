import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';

/* ------------------------------------ TYPES ------------------------------------ */
type productCard = {
  id: number;
  payload: {
    product: productType_ogc | null;
    container: containerType_ogc | null;
    quantity: number | null;
  };
};

export type goodsListState = {
  productCardList: productCard[];
};

// InitialState
const initPayload = { product: null, container: null, quantity: null };
const initialState: goodsListState = {
  productCardList: [
    { id: 0, payload: initPayload },
    { id: 1, payload: initPayload },
    { id: 2, payload: initPayload },
    { id: 3, payload: initPayload },
  ],
};

/* ------------------------------------- SLICE ------------------------------------ */
export const goodsListSlice = createSlice({
  name: 'goodsList',
  initialState,
  reducers: {
    addGoodsListItem: (state) => {
      const currentList = state.productCardList;
      state.productCardList = [
        ...currentList,
        {
          id: currentList[currentList.length - 1].id + 1,
          payload: initPayload,
        },
      ];
    },
    removeGoodsListItem: (state, action: PayloadAction<number>) => {
      const cardID = action.payload;
      state.productCardList = state.productCardList.filter(
        (card) => card.id !== cardID
      );
    },
    updateGoodsList: (state, action: PayloadAction<productCard[]>) => {
      state.productCardList = action.payload;
    },
    updateGoodsListItem: (
      state,
      action: PayloadAction<{
        id: number;
        payloadType: string;
        payloadItem: productType_ogc | containerType_ogc | number;
      }>
    ) => {
      const cardID = action.payload.id;
      const selectedProduct = state.productCardList.find(
        (item) => item.id === cardID
      );
      if (selectedProduct) {
        switch (action.payload.payloadType) {
          case 'product': {
            selectedProduct.payload.product = action.payload.payloadItem as productType_ogc;
            break;
          }
          case 'container': {
            selectedProduct.payload.container = action.payload.payloadItem as containerType_ogc;
            break;
          }
          case 'quantity': {
            selectedProduct.payload.quantity = action.payload.payloadItem as number;
            break;
          }
        }
      } else {
      }
    },
    resetGoodsList: (state) => {
      state.productCardList = initialState.productCardList;
    },
  },
});

export const {
  addGoodsListItem,
  removeGoodsListItem,
  updateGoodsListItem,
  updateGoodsList,
  resetGoodsList,
} = goodsListSlice.actions;

export default goodsListSlice.reducer;
