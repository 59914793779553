import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getFlightsAdapterAPI } from '@/features/api/copApiAdapters/copAdapterAPI';
import { flightAPI } from '@/features/api/copApiEndpoints/flightAPI';

type groundHandlingAction = {
  startTime: string;
  type: string;
  status: string;
  progress: string;
}

export type flight = {
  flight_time: string;
  flight_status: string;
  flight_no: string;
  departureHub: string;
  arrivalHub: string;
  aircraft: string;
  batteries: string;
  cargo: string;
  actions: boolean[];
  clientRef: string;
  groundHandlingActions: groundHandlingAction[];
  order: {
    id: string,
    clientRef: string,
    status: string,
    transportDocumentUrl: string,
  }
}

type PreFlightSlice = {
  flightList: flight[];
};

const initialState: PreFlightSlice = {
  flightList: [],
};

export const preFlightSlice = createSlice({
  name: 'preFlight',
  initialState,
  reducers: {
    updatePreFlightList: (state, action: PayloadAction<flight[]>) => {
      state.flightList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      flightAPI.endpoints.getFlights.matchFulfilled,
      (state, action) => {
        state.flightList = getFlightsAdapterAPI(action.payload);
      }
    );
  },
});

export const { updatePreFlightList } = preFlightSlice.actions;

export default preFlightSlice.reducer;
