import React, { useEffect, useState } from 'react';

import { MainLayout } from '@/features/sharedComponents/MainLayout/MainLayout';
import { useGetFlightsQuery } from '@/features/api/copApiEndpoints/flightAPI';
import FlightTab from './components/FlightTab';

// Styles
import styles from './Preflight.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faPaperPlane, faPlane } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '@/app/hooks';
import { putDroneBody, putDroneParams, usePutDroneMutation } from '@/features/api/copApiEndpoints/droneApi';
import { Autocomplete, Button, Snackbar } from '@mui/joy';
import { hubType } from '@/app/stores/hubSlice';

export function Preflight() {
  const { data: flights, isLoading: getFlightsLoading, isError: getFlightsErr } = useGetFlightsQuery(
    {},
    {
    pollingInterval: 1000,
    refetchOnFocus: true,
  });
  const options = ['Option 1', 'Option 2', 'Option 3'];

  // Repositioning of the drone
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [putDroneSnackbar, setPutDroneSnackbar] = useState<boolean>(false);
  const [putDroneSnackbarError, setPutDroneSnackbarError] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState<hubType | null>(null);
  const hubState = useAppSelector((state) => state.hub);
  const [putDrone, putDroneResult] = usePutDroneMutation(); 
  useEffect(() => {
    if(putDroneResult.isLoading) {setIsLoading(true)}
    else if(putDroneResult.isSuccess) {setIsLoading(false); setPutDroneSnackbar(true);}
    else if(putDroneResult.isError) {setIsLoading(false); setPutDroneSnackbarError(true);}
  },[putDroneResult])
  const handleSubmit = () => {
    if(selectedHub != null) {
      const params: putDroneParams = {reference: '18193051'};
      const body: putDroneBody = {currentHubId: selectedHub.id};
      putDrone({ params, body }); // Use the mutation here
      setSelectedHub(null);
    }
  }

  return (
    <MainLayout>
      <div className={styles.body}>
        <FlightTab/>
        <Autocomplete // Update drone position
          className={styles.putDroneWrapper}
          startDecorator={<FontAwesomeIcon icon={faPlane} />}
          endDecorator={<Button loading={isLoading} onClick={() => handleSubmit()} endDecorator={<FontAwesomeIcon icon={faPaperPlane}/>}>Submit</Button>}
          placeholder='Reposition the drone to...'
          options={hubState.hubList}
          getOptionLabel={(option) => { if (typeof option === 'string') return option; return option.name; }}
          onChange={(event, value) => { if (value && typeof value !== 'string') {setSelectedHub(value as hubType)} else {setSelectedHub(null)} }}
          freeSolo
          size='lg'
        />
        <Snackbar // Display drone reposition SUCCESS result
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={4000}
          open={putDroneSnackbar}
          variant='soft'
          color='success'
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setPutDroneSnackbar(false);
          }}
          endDecorator={
            <Button
              onClick={() => setPutDroneSnackbar(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleCheck}/> The drone has been succesfully repositioned !
        </Snackbar>
        <Snackbar // Display drone reposition ERROR result
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={4000}
          open={putDroneSnackbarError}
          variant='soft'
          color='danger'
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setPutDroneSnackbar(false);
          }}
          endDecorator={
            <Button
              onClick={() => setPutDroneSnackbarError(false)}
              size="sm"
              variant="soft"
              color="danger"
            >
              Dismiss
            </Button>
          }
          size='lg'
        >
          <FontAwesomeIcon icon={faCircleExclamation}/> The drone has not been succesfully repositioned !
        </Snackbar>
      </div>
    </MainLayout>
  );
}