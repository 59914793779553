import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { groundHandlingAPI } from '@/features/api/copApiEndpoints/groundHandlingAPI';
import { getGroundOperationAdapterAPI } from '@/features/api/copApiAdapters/groundHandlingAdapterAPI';
import { fetchCheclistsDataAndMap } from '@/features/functions/downloadJsonFile';
import store from '@/app/store'; // Replace with the actual path

type Task = {
    step: number;
    action: string;
};

export type Checklist = {
  title: string;
  tasksList: Task[];
}

type OngoingGroundHandlingOperationIndex = {
  currentList: number;
  currentTask: number;
};

export type GroundHandlingOperation = {
    type: string;
    startTime: string;
    scheduledTime: string;
    orderId: string;
    orderRef: string;
    droneId: string;
    packages: {}[];
    departureHubId: string;
};

type GroundHandlingOperationStatus = 'COMPLETED' | 'ONGOING' | undefined ;

type OngoingGroundHandlingOperation = {
  groundHandlingOperation: GroundHandlingOperation
  index: OngoingGroundHandlingOperationIndex;
  status: [GroundHandlingOperationStatus, GroundHandlingOperationStatus]; // Element 1 is previous status, Element 2 is current status
  checklistsURL: string;
  checklists: Checklist[];
  ongoingChecklist: Checklist;
}

export type groundHandlingState = {
  groundHandlingOperations: GroundHandlingOperation[];
  ongoingGroundHandlingOperation: OngoingGroundHandlingOperation;
};

// InitialState
const initialGroundHandlingOperation = {
  type: "",
  startTime: "",
  scheduledTime: "",
  orderId: "",
  orderRef: "",
  droneId: "",
  packages: [],
  departureHubId: "",
}
const initialChecklist = {
  title: "Initial Checklist 1 Title",
  tasksList: [
    {step: 1, action: "task 1"},
    {step: 2, action: "task 2"},
    {step: 3, action: "task 3"},
    {step: 4, action: "task 4"},
    {step: 5, action: "task 5"},
    {step: 6, action: "task 6"},
    {step: 7, action: "task 7"},
    {step: 8, action: "task 8"},
  ]
}
const initialChecklists = [
  {...initialChecklist, title: "Checklist 1"}, 
  {...initialChecklist, title: "Checklist 2"},
  {...initialChecklist, title: "Checklist 3"},
  {...initialChecklist, title: "Checklist 4"}
]
const initialState: groundHandlingState = {
  groundHandlingOperations: [],
  ongoingGroundHandlingOperation: {
    groundHandlingOperation: initialGroundHandlingOperation,
    index: {
      currentList: 1,
      currentTask: 1,
    },
    status: [undefined, undefined],
    checklistsURL: "",
    checklists: initialChecklists,
    ongoingChecklist: initialChecklist,
  }
};

/* ------------------------------------- SLICE ------------------------------------ */

export const groundHandlingSlice = createSlice({
  name: 'groundHandling',
  initialState,
  reducers: {
    updateGroundHandlingOperations: (state, action: PayloadAction<GroundHandlingOperation[]>) => {
        state.groundHandlingOperations = action.payload;
    },
    updateOngoingGroundHandlingOperation: (state, action: PayloadAction<GroundHandlingOperation>) => {
      state.ongoingGroundHandlingOperation.groundHandlingOperation = action.payload;
    },
    updateOngoingGroundHandlingOperationIndex: (state, action: PayloadAction<OngoingGroundHandlingOperationIndex>) => {
        state.ongoingGroundHandlingOperation.index = action.payload;
    },
    updateOngoingGroundHandlingOperationStatus: (state, action: PayloadAction<[GroundHandlingOperationStatus, GroundHandlingOperationStatus]>) => {
        state.ongoingGroundHandlingOperation.status = action.payload;
    },
    updateOngoingGroundHandlingOperationChecklists: (state, action: PayloadAction<Checklist[]>) => {
      state.ongoingGroundHandlingOperation.checklists = action.payload;
    },
    updateOngoingGroundHandlingOperationOngoingChecklist: (state, action: PayloadAction<Checklist>) => {
      state.ongoingGroundHandlingOperation.ongoingChecklist = action.payload;
    },
    resetGroundHandlingOperation: (state) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      groundHandlingAPI.endpoints.getGroundHandlingOperations.matchFulfilled,
      (state, action) => {
        state.groundHandlingOperations = getGroundOperationAdapterAPI(action.payload);
      }
    );
    builder.addMatcher(
      groundHandlingAPI.endpoints.getChecklistsURL.matchFulfilled,
      (state, action) => {
        async function fetchData() {
          const response = await fetchCheclistsDataAndMap(action.payload.url);
          store.dispatch(updateOngoingGroundHandlingOperationChecklists(response));
        }
        fetchData()
      }
    );
  },
});

export const {
  updateGroundHandlingOperations,
  updateOngoingGroundHandlingOperation,
  updateOngoingGroundHandlingOperationIndex,
  updateOngoingGroundHandlingOperationStatus,
  updateOngoingGroundHandlingOperationChecklists,
  updateOngoingGroundHandlingOperationOngoingChecklist,
  resetGroundHandlingOperation,
} = groundHandlingSlice.actions;

export default groundHandlingSlice.reducer;
