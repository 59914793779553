import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  // persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { copAPI } from '@/features/api/copAPI';
import counterReducer from '../features/counter/counterSlice';
import sidebarReducer from '../features/sharedComponents/Sidebar/sidebarSlice';
import mobileSidebarReducer from '@/features/sharedComponents/Sidebar/mobileSidebarSlice';
import mainReducer from './mainSlice';
import dashboardReducer from './stores/dataSlice/dashboardSlice';
import fastOrderingReducer from './stores/dataSlice/fastOrderingSlice';
import goodsListReducer from './stores/dataSlice/goodsListSlice';
import orderingReducer from './stores/dataSlice/orderingSlice';
import deliveryReducer from './stores/deliverySlice';
import containerReducer from './stores/containerSlice';
import hubReducer from './stores/hubSlice';
import productReducer from './stores/productSlice';
import groundHandlingReducer from './stores/dataSlice/groundHandlingSlice';
import preFlightReducer from './stores/dataSlice/preFlightSlice';

const reducers = combineReducers({
  // PERSISTENT
  main: persistReducer({ key: 'main', storage }, mainReducer),
  sidebar: persistReducer({ key: 'root', storage }, sidebarReducer),
  // PERSISTENT for remake reducer
  delivery: persistReducer({ key: 'delivery', storage }, deliveryReducer),
  product: persistReducer({ key: 'product', storage }, productReducer),
  hub: persistReducer({ key: 'hub', storage }, hubReducer),
  container: persistReducer({ key: 'container', storage }, containerReducer),
  groundHandling: persistReducer({ key: 'groundHandling', storage }, groundHandlingReducer),
  preFlight: persistReducer({ key: 'preFlight', storage }, preFlightReducer),

  // NOT PERSISTENT
  // groundHandling: groundHandlingReducer,
  mobileSidebar: mobileSidebarReducer,
  ordering: orderingReducer,
  dashboard: dashboardReducer,
  counter: counterReducer,
  goodsList: goodsListReducer,
  fastOrdering: fastOrderingReducer,
  // API
  [copAPI.reducerPath]: copAPI.reducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
    .concat(copAPI.middleware),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;