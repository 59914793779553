import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { productsAPI } from '@/features/api/copApiAdapters/copAdapterAPI';
import { productAPI } from '@/features/api/copApiEndpoints/productAPI';

export type productType_ogc = {
  id: string;
  un: string;
  name: string;
  psn: string;
  primary_class: string;
  subsidiary_class: string;
  packing_group: string;
  labels: { prim_label: string; sec_label: string };
};

type ProductSlice = {
  productList: productType_ogc[];
};

const initialState: ProductSlice = {
  productList: [],
};

export type containerType_ogc = {
  id: string;
  name: string;
};

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateProductList: (state, action: PayloadAction<productType_ogc[]>) => {
      state.productList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productAPI.endpoints.getProducts.matchFulfilled,
      (state, action) => {
        state.productList = productsAPI(action.payload);
      }
    );
  },
});

export const { updateProductList } = productSlice.actions;

export default productSlice.reducer;
