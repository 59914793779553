import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// State types
type logo = {
  id: string,
  author: string,
  height: number,
  width: number,
  url: string,
  download_url: string,
}

// InitialState type
export type sidebarState = {
  logo: logo;
  status: 'idle' | 'loading' | 'failed';
  activePage: string;
}

const initialState: sidebarState = {
  logo: {
    id: '0000',
    author: 'ADLC BV',
    height: 400,
    width: 300,
    url: '123',
    download_url: '123',
  },
  status: 'idle',
  activePage: 'Dashboard',
};

export const incrementAsync = createAsyncThunk(
  'logo/getLogo',
  async (amount: number) => {
    const response = {
      id: '0000',
      author: 'ADLC BV',
      height: 400,
      width: 300,
      url: '123',
      download_url: '123',
    };
    return response;
  }
);

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    updateActivePage: (state, action: PayloadAction<string>) => {
      state.activePage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action: PayloadAction<logo>) => {
        state.status = 'idle';
        state.logo = action.payload;
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { updateActivePage } = sidebarSlice.actions;

export default sidebarSlice.reducer;