import { ComingDelivery, Delivery } from '@/app/stores/deliverySlice';
import { hubType } from '@/app/stores/hubSlice';
import { containerType_ogc, productType_ogc } from '@/app/stores/productSlice';
import { FlightsReturn } from '../copApiEndpoints/flightAPI';
import { flight } from '@/app/stores/dataSlice/preFlightSlice';

const flightStatusAdapter = (status: string) => {
  let adaptedStatus: string = '';
  switch (status) {
    case 'SCHEDULED':
      return (adaptedStatus = 'Scheduled');
    case 'IN_APPROVAL':
      return (adaptedStatus = 'Waiting for approval');
    case 'APPROVED':
      return (adaptedStatus = 'Approved');
    case 'IN_PREPARATION':
      return (adaptedStatus = 'In preparation');
    case 'READY_TO_TAKE_OFF':
      return (adaptedStatus = 'Ready to take-off');
    case 'IN_FLIGHT':
      return (adaptedStatus = 'In flight');
    case 'LANDED':
      return (adaptedStatus = 'Landed');
    case 'CANCELED':
      return (adaptedStatus = 'Cancelled');
  }
  return adaptedStatus;
};

const statusAdapter = (status: string) => {
  let adaptedStatus;
  switch (status) {
    case 'pending':
      return (adaptedStatus = 'Pending');
    case 'scheduled':
      return (adaptedStatus = 'Scheduled');
    case 'approved':
      return (adaptedStatus = 'Approved');
    case 'ready':
      return (adaptedStatus = 'Ready');
    case 'ready-to-load':
      return (adaptedStatus = 'Ready to Load');
    case 'loading':
      return (adaptedStatus = 'Loading');
    case 'loaded':
      return (adaptedStatus = 'Loaded');
    case 'in-flight':
      return (adaptedStatus = 'In flight');
    case 'ready-to-unload':
      return (adaptedStatus = 'Ready to Unload');
    case 'unloading':
      return (adaptedStatus = 'Unloading');
    // TODO: ask BE to fix spelling for status value
    case 'CANCELED':
    case 'canceled':
    case 'cancelled':
      return (adaptedStatus = 'Cancelled');
    case 'completed':
      return (adaptedStatus = 'Completed');
    case 'aborted':
      return (adaptedStatus = 'Aborted');
    default:
      break;
  }
  return adaptedStatus;
};

export function productsAPI(data: any): productType_ogc[] {
  // This function maps the fetched products data to the products redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      return {
        id: item.id,
        un: item.unNumber || '---',
        name: item.name,
        psn: item.properShippingName || '---',
        primary_class: item.hazards? item.hazards[0] : '---',
        subsidiary_class: item.hazards? item.hazards[1] : '---',
        packing_group: item.packingGroup || '---',
        // labels: {
        //   prim_label: '',
        //   sec_label: '',
        // },
      };
    });
  }
}

export function ordersAPI(data: any): Delivery[] {
  // This function maps the fetched order data to the delivery redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      // Define n.a. fields
      let prepared_by, unloaded_by;
      if (item.preparedBy == null) { prepared_by = '---' } else { prepared_by = item.preparedBy.name };
      if (item.unloadedBy == null) { unloaded_by = '---' } else { unloaded_by = item.unloadedBy.name };

      return {
        id: item.id,
        no: item.clientRef,
        ordered_by: item.orderedBy.name,
        prepared_by: prepared_by,
        unloaded_by: unloaded_by,
        departure: item.startHub.name,
        arrival: item.endHub.name,
        etr: item.timeReadiness,
        eta: item.timeArrival || '',
        status: statusAdapter(item.status),
        payload: item.payload,
      };
    });
  }
}

export function ordersRtAPI(data: any): ComingDelivery[] {
  // This function maps the fetched order data to the comingDelivery redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      const currentTime = new Date();
      const arrivalTime = new Date(item.timeArrival);
      const diffTime = arrivalTime.getTime() - currentTime.getTime();

      const progress: number = (() => {
        switch (item.status) {
          case 'pending':
          case 'scheduled':
          case 'approved':
            return 0;
            //  Status is 5% after the “Prepare empty flight” is done (Update API)
          case 'ready':
            return 35;
          case 'ready-to-load':
          case 'loading':
          case 'loaded':
            return 40;
          case 'in-flight':
          case 'ready-to-unload':
            return 95;
          case 'completed':
            return 100;
          case 'cancelled':
          case 'aborted':
          default:
            return 0;
        }
      })()
      
      const remainingTime = item.timeArrival ? diffTime / 60000 : undefined;
      return {
        id: item.id,
        no: item.clientRef,
        departure: item.startHub.name,
        arrival: item.endHub.name,
        remaining_time: remainingTime?.toString() ?? undefined, // tbd
        progress: progress, // tbd
      };
    });
  }
}

export function hubsAPI(data: any): hubType[] {
  // This function maps the fetched hub data to the hub redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        picture: item.img_url || 'sgs_logo.png',
        location: item.location,
        lng: item.lng,
        lat: item.lat,
        companyId: item.companyId,
      };
    });
  }
}

export function containersAPI(data: any): containerType_ogc[] {
  // This function maps the fetched products data to the products redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  }
}



export function getFlightsAdapterAPI(data: FlightsReturn[]): flight[] {
  // This function maps the fetched products data to the products redux state.
  if (typeof data === 'undefined') {
    return [];
  } else {
    return data.map((item: any) => {

      let actions: boolean[];
      switch (item.latestStatus) {
        case 'IN_FLIGHT': actions = [false, false, true]; break;
        case 'READY_TO_TAKE_OFF': actions = [false, true, false]; break;
        case 'APPROVED': actions = [true, false, false]; break;
        default: actions = [false, false, false]; break;
      }

      let clientRef, cargo, preGroundHandlingType, postGroundHandlingType: string; let orderInfo;
      if (item.deliveryFlight) { 
        clientRef = item?.deliveryForDeliveryFlight.order.clientRef; 
        cargo = 'Cargo flight';
        preGroundHandlingType = 'Prepare delivery flight';
        postGroundHandlingType = 'Complete delivery flight';
        orderInfo = {
          id: item?.deliveryForDeliveryFlight?.order.id,
          clientRef: item?.deliveryForDeliveryFlight?.order.clientRef,
          status: item?.deliveryForDeliveryFlight?.order.status,
          transportDocumentUrl: item?.deliveryForDeliveryFlight?.order.transportDocumentUrl,
        }
      }
      else {
        clientRef = item?.deliveryForRepositionFlight.order.clientRef; 
        cargo = 'Empty flight';
        preGroundHandlingType = 'Prepare empty flight';
        postGroundHandlingType = 'Complete empty flight';
        orderInfo = {
          id: item?.deliveryForRepositionFlight?.order.id,
          clientRef: item?.deliveryForRepositionFlight?.order.clientRef,
          status: item?.deliveryForRepositionFlight?.order.status,
          transportDocumentUrl: item?.deliveryForRepositionFlight?.order.transportDocumentUrl,
        }
      }

      return {
        flight_status: flightStatusAdapter(item.latestStatus),
        flight_time: item.departureTime,
        flight_no: item.reference,
        departureHub: item.takeoffHubId,
        arrivalHub: item.landingHubId,
        aircraft: item.drone?.model,
        batteries: item.drone?.currentBatteries,
        cargo: cargo,
        actions: actions,
        clientRef: clientRef,
        groundHandlingActions: [
          {
            startTime: item.preGroundHandlingStartTime,
            type: preGroundHandlingType,
            status: item.preGroundHandlingStatus,
            progress: item.preGroundHandlingChecklistProgress,
          },
          {
            startTime: item.postGroundHandlingStartTime,
            type: postGroundHandlingType,
            status: item.postGroundHandlingStatus,
            progress: item.postGroundHandlingChecklistProgress,
          }
        ],
        order: orderInfo
      };
    });
  }
}