import { useEffect, useRef, useState } from "react";
import { CircularProgress, Divider, Modal, Textarea } from "@mui/joy";
import { getAlertCommentsParams, getAlertCommentsReturn, postCommentAlertBody, useGetAlertCommentsQuery, usePostCommentAlertMutation } from "@/features/api/copApiEndpoints/alertsAPI";

import styles from './AlertComments.module.scss';
import { IconButton } from "@/features/sharedComponents/Buttons/iconButton/IconButton";
import { faArrowRight, faArrowRightFromBracket, faBell, faCheckCircle, faCircleExclamation, faPaperPlane, faRotateRight, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DelayingAppearance from "@/features/sharedComponents/Modal/LoadingUI";

type apiCallStatus = 'ERROR' | 'SUCCESS' | 'LOADING' | undefined;
type commentList = {
    content: string;
    timestamp: string;
    user: string;
}

export default function AlertComments({
    open,
    handleClose,
    alertId,
}: {
    open: boolean;
    handleClose: () => void;
    alertId: string;
}) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [displayResult, setDisplayResult] = useState<apiCallStatus>(undefined);  
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [postCommentAlert, postCommentAlertResult] = usePostCommentAlertMutation();

    // Fetch comments
    const params: getAlertCommentsParams = {alertId: alertId};
    const { isError: getAlertCommentsIsError, isLoading: getAlertCommentsIsLoading, data: getAlertCommentsData, refetch: getAlertCommentsRefetch } = useGetAlertCommentsQuery(params);
    
    // Display result after submission
    useEffect(() => {
        if (postCommentAlertResult.isError) { setDisplayResult('ERROR') } 
        else if (postCommentAlertResult.isLoading) { setDisplayResult('LOADING') }
        else if (postCommentAlertResult.isSuccess) { setDisplayResult('SUCCESS'); getAlertCommentsRefetch() }
    }, [postCommentAlertResult]);

    // Handled submission
    const handleSubmit = () => {
        if(message != undefined) {
            const body: postCommentAlertBody = {text: message};
            postCommentAlert({alertId, body})
        }
    }

    // Random data instead of fetched data
    const commentsList: commentList[] = [
        {
            content: 'Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1',
            timestamp: `${new Date().toDateString()} , ${new Date().toLocaleTimeString()}`,
            user: 'Rayan Quintaes'
        },
        {
            content: 'Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1',
            timestamp: `${new Date().toDateString()} , ${new Date().toLocaleTimeString()}`,
            user: 'Grégoire Moreau'
        },
        {
            content: 'Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1',
            timestamp: `${new Date().toDateString()} , ${new Date().toLocaleTimeString()}`,
            user: 'Marc Kegelaers'
        },
        {
            content: 'Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1',
            timestamp: `${new Date().toDateString()} , ${new Date().toLocaleTimeString()}`,
            user: 'Rayan Quintaes'
        },
        {
            content: 'Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1',
            timestamp: `${new Date().toDateString()} , ${new Date().toLocaleTimeString()}`,
            user: 'Grégoire Moreau'
        },
        {
            content: 'Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1 Content message 1',
            timestamp: `${new Date().toDateString()} , ${new Date().toLocaleTimeString()}`,
            user: 'Marc Kegelaers'
        },
    ];

    return (
        <Modal
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {isLoading ? (
                <CircularProgress color="neutral" variant="soft"/>
            ):(
                <>
                    {(displayResult == undefined) && <div className={styles.commentsModal}> 
                        <div className={styles.header}>
                            <p> <FontAwesomeIcon icon={faTriangleExclamation} size="sm"/> Alert Reporting </p>
                            <div className={styles.subTitle}>
                                Id : {alertId}
                            </div>
                        </div>
                        <div className={styles.newComment}>
                            <div style={{paddingTop: '15px', paddingBottom: '10px', fontSize: '12px', color: 'rgb(117, 117, 117)'}}>Add a new comment</div>
                            <Textarea variant="soft" color="neutral" onChange={(event) => setMessage(event?.target.value)} minRows={1} maxRows={3} sx={{fontSize: '12px', minWidth:'250px'}}/>
                        </div>
                        <div className={styles.oldComments}>
                            <div style={{paddingTop: '15px', paddingBottom: '10px', fontSize: '12px', color: 'rgb(44, 113, 211, 50%)'}}> Previous comments</div>
                            <div className={styles.listCommentsWrapper}>
                                {[...getAlertCommentsData ?? []]?.filter(item => item.text?.trim().length != 0).reverse().map((item, idx) => (
                                    <div key={idx} className={styles.commentWrapper}>
                                        <div className={styles.commentContent}>{item.text}</div>
                                        <div className={styles.commentFooter}>
                                            <div className={styles.commentTimestamp}>{`${new Date(item.timestamp ?? '').toDateString()} , ${new Date(item?.timestamp ?? '').toLocaleTimeString()}`}</div>
                                            <Divider orientation="vertical"/>
                                            <div className={styles.commentUser}>{item.userName}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <>
                                <IconButton
                                    icon={faArrowRightFromBracket}
                                    content='Exit'
                                    onClick={handleClose}
                                    noBorder={true}
                                    rotate='rotate180'
                                    color='unset_blue'
                                />
                                <IconButton
                                    icon={faPaperPlane}
                                    content='Submit'
                                    onClick={handleSubmit}
                                    reversed={true}
                                    disabled={message == undefined || message.trim().length == 0}
                                />
                            </>
                        </div>
                    </div>}
                    {(displayResult == 'SUCCESS') && <div className={styles.commentsModalSuccess}>
                    <div className={styles.header}>
                        <FontAwesomeIcon icon={faCheckCircle} size='xl'/> Alert succesfully placed !
                    </div>
                    <div className={styles.footer}>
                        < IconButton
                        icon={faXmark}
                        content='Close'
                        onClick={handleClose}
                        reversed={true}
                        />
                    </div>
                    </div>}
                    {(displayResult == 'LOADING') && <div className={styles.commentsModalLoading}>
                        <DelayingAppearance color='rgb(6, 95, 217)' />
                    </div>}
                    {(displayResult == 'ERROR') && <div className={styles.commentsModalError}>
                    <div className={styles.header}>
                        <FontAwesomeIcon icon={faCircleExclamation} size='xl'/> Something went wrong...
                    </div>
                    <div className={styles.footer}>
                        < IconButton
                        icon={faRotateRight}
                        content='Try again'
                        onClick={handleClose}
                        reversed={true}
                        />
                    </div>
                    </div>}
                </>
            )}
        </Modal>
    );
}
